import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaChartLine, FaClock, FaCog, FaSignOutAlt, FaBell } from 'react-icons/fa';
import AuthContext from '../context/AuthContext';
import logo from '../assets/logo.jpeg'; // Ensure you have a logo.jpeg in your assets folder

const Sidebar = () => {
  const { logout } = useContext(AuthContext);

  return (
    <div className="hidden md:block fixed top-0 left-0 h-screen bg-[#11024D] text-white p-4 w-64 z-40 shadow-lg">
      <nav className="mt-10">
        <div className="p-4 text-center">
          <img src={logo} alt="Logo" className="w-24 mx-auto" />
        </div>
        <ul>
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
            <FaHome className="mr-2" />
            <Link to="/profile">Profile</Link>
          </li>
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
            <FaChartLine className="mr-2" />
            <Link to="/results">Results</Link>
          </li>
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
            <FaClock className="mr-2" />
            <Link to="/timetable">Timetable</Link>
          </li>
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
            <FaCog className="mr-2" />
            <Link to="/settings">Settings</Link>
          </li>
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
            <FaHome className="mr-2" />
            <Link to="/financials">Financials</Link>
          </li>
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
            <FaBell className="mr-2" />
            <Link to="/notifications">Notifications</Link>
          </li>
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
            <FaSignOutAlt className="mr-2" />
            <button onClick={logout}>Logout</button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
