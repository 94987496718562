import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from './Api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [regNumber, setRegNumber] = useState(null);

  // Initialize state from localStorage on first render
  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    const savedRegNumber = localStorage.getItem('regNumber');
    if (savedToken) {
      setToken(savedToken);
      setRegNumber(savedRegNumber);
      setIsAuthenticated(true);
    }
  }, []);

  const login = async (regNumber, password) => {
    try {
      const response = await axios.post(`${BASE_URL}/student-auth/student-login`, { regNumber, password });
      const { token } = response.data;

      // Save to localStorage and update state
      localStorage.setItem('token', token);
      localStorage.setItem('regNumber', regNumber);
      setToken(token);
      setRegNumber(regNumber);
      setIsAuthenticated(true);
    } catch (error) {
      throw new Error(error.response?.data?.message || 'An unknown error occurred');
    }
  };

  const logout = () => {
    // Clear localStorage and reset state
    localStorage.removeItem('token');
    localStorage.removeItem('regNumber');
    setToken(null);
    setRegNumber(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, regNumber, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
