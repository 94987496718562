import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from '../context/AuthContext';
import aspire from '../assets/background.jpg';

const Login = () => {
  const [regNumber, setRegNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { login, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(regNumber, password);
    } catch (error) {
      if (error.message === 'Invalid credentials') {
        setShowModal(true);
      } else {
        toast.error(`Login failed: ${error.message}`);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/profile');
    }
  }, [isAuthenticated, navigate]);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center bg-fixed"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)), url(${aspire})`,
      }}
    >
      <div className="w-full max-w-md px-4">
        <div className="text-center mb-10">
          <h1 className="text-4xl font-bold text-white mb-2 drop-shadow-lg">
            School Made Eazy
          </h1>
          <p className="text-gray-200 text-lg">Student Portal</p>
        </div>

        <div className="bg-white rounded-2xl shadow-2xl p-8 transform transition-all duration-300 hover:scale-[1.01]">
          <h2 className="text-2xl font-bold text-gray-800 mb-8 text-center">
            Welcome Back!
          </h2>

          <form onSubmit={handleLogin} className="space-y-6">
            <div>
              <label 
                className="block text-sm font-medium text-gray-700 mb-2" 
                htmlFor="regNumber"
              >
                Registration Number
              </label>
              <input
                type="text"
                id="regNumber"
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
                value={regNumber}
                onChange={(e) => setRegNumber(e.target.value)}
                placeholder="Enter your reg number"
                required
              />
            </div>

            <div>
              <label 
                className="block text-sm font-medium text-gray-700 mb-2" 
                htmlFor="password"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full bg-[#11024D] text-white py-3 rounded-lg font-medium
                hover:bg-[#1a0375] transform hover:-translate-y-0.5 transition-all duration-200
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#11024D]
                shadow-lg hover:shadow-xl"
            >
              Sign In
            </button>
          </form>
        </div>
      </div>

      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* Error Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 px-4">
          <div className="fixed inset-0 bg-black/70" onClick={closeModal}></div>
          <div className="bg-white rounded-2xl shadow-2xl p-8 max-w-md w-full relative z-10 transform transition-all">
            <div className="text-center">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 mb-4">
                <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <h3 className="text-lg font-medium text-gray-900 mb-2">Login Failed</h3>
              <p className="text-sm text-gray-500">
                Your registration number or password might be incorrect. Please try again.
              </p>
            </div>
            <div className="mt-6">
              <button
                onClick={closeModal}
                className="w-full inline-flex justify-center rounded-lg border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors duration-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
