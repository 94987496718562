import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Navbar from './components/Navbar'; // Mobile Navbar
import Sidebar from './components/Sidebar'; // Desktop Sidebar
import Login from './pages/Login';
import Financials from './pages/Financials';
import Profile from './pages/Profile';
import Results from './pages/Results';
import Settings from './pages/Settings';
import Timetable from './pages/Timetable';
import Notifications from './pages/Notifications';

const App = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/';
  const showSidebar = !isLoginPage;

  return (
    <AuthProvider>
      <div className="flex flex-col md:flex-row min-h-screen">
        {/* Navbar (Mobile Only) */}
        {showSidebar && (
          <Navbar className="md:hidden fixed top-0 left-0 w-full z-50" />
        )}

        {/* Sidebar (Desktop Only) */}
        {showSidebar && (
          <Sidebar className="hidden md:block w-64 bg-gray-800 text-white fixed top-0 left-0 h-full" />
        )}

        {/* Main Content */}
        <div className={`flex-1 ${showSidebar ? 'md:ml-64 mt-10 md:mt-0' : ''}`}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/financials"
              element={
                <PrivateRoute>
                  <Financials />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/results"
              element={
                <PrivateRoute>
                  <Results />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
            <Route
              path="/timetable"
              element={
                <PrivateRoute>
                  <Timetable />
                </PrivateRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <PrivateRoute>
                  <Notifications />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </div>
    </AuthProvider>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
