import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/Api';
import AuthContext from '../context/AuthContext';

const LoadingSpinner = () => (
  <div className="flex justify-center items-center">
    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-[#11024D]"></div>
  </div>
);

const Timetable = () => {
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [timetableEntries, setTimetableEntries] = useState({});
  const [selectedDay, setSelectedDay] = useState('Monday');
  const { username } = useContext(AuthContext);
  const [loadingClasses, setLoadingClasses] = useState(true);
  const [loadingTimetable, setLoadingTimetable] = useState(false);

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    setLoadingClasses(true);
    try {
      const response = await axios.get(`${BASE_URL}/gradelevelclasses/get-all-gradelevelclasses`);
      setClasses(response.data.classes);
    } catch (error) {
      console.error('Error fetching classes:', error);
    } finally {
      setLoadingClasses(false);
    }
  };

  const fetchTimetable = async () => {
    if (!selectedClass) return;
    setLoadingTimetable(true);
    try {
      const response = await axios.get(`${BASE_URL}/timetables/get-timetable/${selectedClass}`);
      setTimetableEntries(response.data);
    } catch (error) {
      console.error('Error fetching timetable:', error);
    } finally {
      setLoadingTimetable(false);
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-6 md:px-6 bg-gray-100 rounded-md shadow-md mt-4 md:mt-10">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
        <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-4 md:mb-0">View Timetable</h2>
      </div>

      <div className="space-y-4 md:space-y-6">
        <div>
          <label className="block text-sm md:text-base font-semibold text-gray-700 mb-2">
            Select Class:
          </label>
          {loadingClasses ? (
            <div className="py-2">
              <LoadingSpinner />
            </div>
          ) : (
            <select
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2.5 focus:ring-2 focus:ring-[#11024D] text-sm md:text-base"
            >
              <option value="">Select a class</option>
              {classes.map((cls) => (
                <option key={cls.ClassID} value={cls.ClassID}>
                  {cls.ClassName}
                </option>
              ))}
            </select>
          )}
        </div>

        <button
          onClick={fetchTimetable}
          disabled={loadingTimetable || !selectedClass}
          className="w-full md:w-auto bg-[#11024D] text-white py-2.5 px-6 rounded-md font-semibold text-sm md:text-base hover:bg-[#0a0236] transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed flex justify-center items-center gap-2"
        >
          {loadingTimetable ? (
            <>
              <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
              Loading...
            </>
          ) : (
            'View Timetable'
          )}
        </button>

        {Object.keys(timetableEntries).length > 0 && (
          <div className="mt-8">
            <div className="mb-6">
              <label className="block text-sm md:text-base font-semibold text-gray-700 mb-2">
                Select Day:
              </label>
              <select
                value={selectedDay}
                onChange={(e) => setSelectedDay(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2.5 focus:ring-2 focus:ring-[#11024D] text-sm md:text-base"
              >
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
            </div>
            
            <h3 className="text-lg md:text-xl font-bold text-gray-700 mb-4">
              Timetable for {selectedDay}
            </h3>
            
            <div className="relative overflow-x-auto shadow-md rounded-lg">
              <div className="overflow-x-auto scrollbar-thin scrollbar-thumb-[#11024D] scrollbar-track-gray-100">
                <table className="w-full text-sm md:text-base">
                  <thead className="bg-[#11024D] text-white">
                    <tr>
                      <th className="px-4 py-3 text-left whitespace-nowrap">Item</th>
                      <th className="px-4 py-3 text-left whitespace-nowrap">Start Time</th>
                      <th className="px-4 py-3 text-left whitespace-nowrap">End Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(timetableEntries[selectedDay] || []).map((entry, idx) => (
                      <tr
                        key={`${selectedDay}-${idx}`}
                        className={`${
                          idx % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                        } hover:bg-gray-100 transition-colors duration-200`}
                      >
                        <td className="px-4 py-3 whitespace-nowrap">{entry.Item}</td>
                        <td className="px-4 py-3 whitespace-nowrap">{entry.StartTime}</td>
                        <td className="px-4 py-3 whitespace-nowrap">{entry.EndTime}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mt-2 text-sm text-gray-500 text-center">
              Scroll horizontally to view all entries
            </div>
          </div>
        )}

        {selectedClass && !loadingTimetable && Object.keys(timetableEntries).length === 0 && (
          <div className="mt-6 p-4 bg-red-50 border border-red-200 rounded-md">
            <p className="text-center font-semibold text-red-600 text-sm md:text-base">
              Hey, the timetable for this class doesn't exist yet.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Timetable;