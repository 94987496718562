import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/Api';
import { format } from 'date-fns';
import AuthContext from '../context/AuthContext';

const Financials = () => {
  const { regNumber } = useContext(AuthContext);
  const [student, setStudent] = useState(null);
  const [balances, setBalances] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [searchParams, setSearchParams] = useState({
    term: '',
    year: '',
    paynow_reference: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (regNumber) {
      fetchStudentRecords();
    }
  }, [regNumber]);

  const fetchStudentRecords = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${BASE_URL}/payments/get-student-records/${regNumber}`);
      setStudent(response.data.student);
      setBalances(response.data.balances);
      setTransactions(response.data.transactions);
    } catch (err) {
      console.error('Error fetching student records:', err);
      setError('Failed to fetch student records.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchParams(prevParams => ({
      ...prevParams,
      [name]: value
    }));
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/payments/search-student-record/${regNumber}`, {
        params: searchParams
      });
      setTransactions(response.data.transactions);
    } catch (err) {
      console.error('Error searching transactions:', err);
      setError('Failed to search transactions.');
    } finally {
      setLoading(false);
    }
  };

  const openModal = (transaction) => {
    setSelectedTransaction(transaction);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedTransaction(null);
    setShowModal(false);
  };

  return (
    <div className="max-w-full">
      <h2 className="text-xl mt-8 text-gray-700 text-center font-bold mb-4">
        Student Financial Records
      </h2>
      {loading && (
        <div className="flex justify-center items-center">
          <div
            className="spinner"
            style={{ borderColor: '#11024D transparent transparent transparent' }}
          ></div>
        </div>
      )}

      {error && <div className="text-red-500 text-center">{error}</div>}

      {student && (
        <div className="mb-4 p-6 bg-white rounded shadow-md">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <p className="text-gray-500 font-semibold">Reg Number:</p>
              <p>{student.RegNumber}</p>
            </div>
            <div>
              <p className="text-gray-500 font-semibold">Name:</p>
              <p>
                {student.Name} {student.Surname}
              </p>
            </div>
            {balances.map((balance) => (
              <React.Fragment key={balance.id}>
                <div>
                  <p className="text-gray-500 font-semibold">Term:</p>
                  <p>{balance.term}</p>
                </div>
                <div>
                  <p className="text-gray-500 font-semibold">Year:</p>
                  <p>{balance.year}</p>
                </div>
                <div>
                  <p className="text-gray-500 font-semibold">Balance:</p>
                  <p>
                    {balance.balance} ({balance.balance_type})
                  </p>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      )}

      <div className="mb-4 p-4 bg-white rounded shadow-md">
        <h3 className="font-semibold text-gray-700 text-lg mb-4 text-center sm:text-left">
          Search Transactions
        </h3>
        <form
          onSubmit={handleSearch}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4"
        >
          <div>
            <label className="block text-gray-700 font-semibold">Term</label>
            <select
              name="term"
              value={searchParams.term}
              onChange={handleSearchChange}
              className="mt-1 p-2 border rounded w-full"
            >
              <option value="">All Terms</option>
              <option value="1">Term 1</option>
              <option value="2">Term 2</option>
              <option value="3">Term 3</option>
            </select>
          </div>

          <div>
            <label className="block text-gray-700 font-semibold">Year</label>
            <input
              type="number"
              name="year"
              value={searchParams.year}
              onChange={handleSearchChange}
              className="mt-1 p-2 border rounded w-full"
              placeholder="e.g., 2024"
            />
          </div>

          <div>
            <label className="block text-gray-700 font-semibold">Txn Reference</label>
            <input
              type="text"
              name="paynow_reference"
              value={searchParams.paynow_reference}
              onChange={handleSearchChange}
              className="mt-1 p-2 border rounded w-full"
              placeholder="Reference"
            />
          </div>

          <div className="flex items-end justify-center sm:justify-end">
            <button
              type="submit"
              className="bg-[#11024D] text-white px-4 py-2 rounded w-full sm:w-auto"
            >
              Search Transactions
            </button>
          </div>
        </form>
      </div>

      {transactions.length > 0 ? (
        <div className="p-4 bg-white rounded shadow-md">
          <h3 className="text-lg text-gray-700 font-semibold mb-2">
            Transactions
          </h3>
          <div className="relative">
            <div className="w-full overflow-x-auto shadow ring-1 ring-black ring-opacity-5 rounded-lg">
              <div className="min-w-full inline-block align-middle">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead style={{ backgroundColor: '#11024D' }}>
                    <tr>
                      <th className="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider whitespace-nowrap">
                        Date
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider whitespace-nowrap">
                        Txn Ref
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider whitespace-nowrap">
                        Received Amount
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider whitespace-nowrap">
                        Reported Amount
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider whitespace-nowrap">
                        Currency
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider whitespace-nowrap">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {transactions.map((transaction) => (
                      <tr key={transaction.id} className="hover:bg-indigo-50">
                        <td className="px-4 py-3 whitespace-nowrap text-sm">
                          {format(new Date(transaction.created_at), 'yyyy-MM-dd HH:mm')}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm">
                          {transaction.paynow_reference}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm">
                          {transaction.received_amount}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm">
                          {transaction.reported_amount}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm">
                          {transaction.currency}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm">
                          <button
                            className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded text-sm transition-colors duration-150"
                            onClick={() => openModal(transaction)}
                          >
                            View Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        !loading && (
          <div className="text-center text-gray-500 mt-6">
            <p>No transaction records at the moment.</p>
          </div>
        )
      )}

      {showModal && selectedTransaction && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full mx-4">
            <h3 className="text-lg font-bold mb-4">Transaction Details</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <strong>Reg Number:</strong> {selectedTransaction.reg_number}
              </div>
              <div>
                <strong>Form:</strong> {selectedTransaction.form}
              </div>
              <div>
                <strong>Term:</strong> {selectedTransaction.term}
              </div>
              <div>
                <strong>Year:</strong> {selectedTransaction.year}
              </div>
              <div>
                <strong>Txn Ref:</strong> {selectedTransaction.paynow_reference}
              </div>
              <div>
                <strong>Date:</strong>{' '}
                {format(new Date(selectedTransaction.created_at), 'yyyy-MM-dd HH:mm')}
              </div>
              <div>
                <strong>Received Amount:</strong>{' '}
                {selectedTransaction.received_amount}
              </div>
              <div>
                <strong>Reported Amount:</strong>{' '}
                {selectedTransaction.reported_amount}
              </div>
              <div>
                <strong>Currency:</strong> {selectedTransaction.currency}
              </div>
            </div>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition-colors duration-150"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Financials;