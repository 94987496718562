import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { BASE_URL } from '../context/Api';
import logo from '../assets/logo.png';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const PdfContent = ({ student, year, termID }) => {
  if (!student) return null;

  return (
    <div
      id="pdf-content"
      className="p-4 md:p-6 font-inter text-sm leading-relaxed bg-white text-gray-900"
    >
      <div className="flex justify-between items-start gap-4">
        <div>
          <h1 className="text-lg font-bold text-yellow-600">SCHOOL MADE EAZY</h1>
          <p>Washayabunha</p>
          <p>Seke Chitungwiza</p>
          <p>+263 778 975 981</p>
          <p>+263 776 533 070</p>
        </div>
        <img
          src={logo}
          alt="School Logo"
          className="w-20 md:w-24 h-auto rounded-md"
        />
      </div>

      <div className="mt-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="flex flex-col md:flex-row">
            <p className="w-32 font-bold">Name:</p>
            <p>{student.Name} {student.Surname}</p>
          </div>
          <div className="flex flex-col md:flex-row">
            <p className="w-32 font-bold">RegNumber:</p>
            <p>{student.RegNumber}</p>
          </div>
          <div className="flex flex-col md:flex-row">
            <p className="w-32 font-bold">Class Position:</p>
            <p>{student.classPosition || "N/A"}</p>
          </div>
          <div className="flex flex-col md:flex-row">
            <p className="w-32 font-bold">Term:</p>
            <p>{termID}</p>
          </div>
          <div className="flex flex-col md:flex-row md:col-span-2">
            <p className="w-32 font-bold">Year:</p>
            <p>{year}</p>
          </div>
        </div>
      </div>

      <div className="mt-6 overflow-x-auto">
        <table className="min-w-full table-auto border-collapse border border-gray-300 rounded-t-lg">
          <thead className="bg-gray-900 text-white">
            <tr>
              <th className="border border-gray-300 px-2 py-1 text-left whitespace-nowrap">Subject</th>
              <th className="border border-gray-300 px-2 py-1 text-left whitespace-nowrap">Final Mark</th>
              <th className="border border-gray-300 px-2 py-1 text-left whitespace-nowrap">Grade</th>
              <th className="border border-gray-300 px-2 py-1 text-left whitespace-nowrap md:whitespace-normal">Comment</th>
              <th className="border border-gray-300 px-2 py-1 text-left whitespace-nowrap">Paper Marks</th>
            </tr>
          </thead>
          <tbody>
            {student.subjectResults?.map((subject, idx) => (
              <tr key={idx} className={idx % 2 === 0 ? "bg-gray-100" : ""}>
                <td className="border border-gray-300 px-2 py-1 text-left whitespace-nowrap">{subject.SubjectName}</td>
                <td className="border border-gray-300 px-2 py-1 text-left whitespace-nowrap">{subject.AverageMark}</td>
                <td className="border border-gray-300 px-2 py-1 text-left whitespace-nowrap">{subject.Grade}</td>
                <td className="border border-gray-300 px-2 py-1 text-left whitespace-nowrap md:whitespace-normal break-words md:max-w-[300px]">
                  {subject.Comment}
                </td>
                <td className="border border-gray-300 px-2 py-1 text-left whitespace-nowrap">
                  {subject.PaperMarks.map((paper) => (
                    <p key={paper.PaperName}>
                      {paper.PaperName}: {paper.Mark}
                    </p>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-12 flex flex-col md:flex-row justify-between gap-6 md:gap-0">
        <div>
          <p>Headmaster's Signature</p>
          <div className="border-t border-dotted border-gray-500 w-40 mt-2"></div>
        </div>
        <div>
          <p>School Stamp</p>
          <div className="border-t border-dotted border-gray-500 w-40 mt-2"></div>
        </div>
      </div>

      <div className="mt-12 border-t border-gray-300 pt-4 text-center italic text-gray-500">
        This is a digital report. Have it stamped at the school for official use.
      </div>
    </div>
  );
};

const Results = () => {
  const { regNumber } = useContext(AuthContext);
  const [term, setTerm] = useState('');
  const [year, setYear] = useState('');
  const [classID, setClassID] = useState('');
  const [form, setForm] = useState('');
  const [gradeLevelClasses, setGradeLevelClasses] = useState([]);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [studentInfo, setStudentInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchGradeLevelClasses();
    fetchStudentInfo();
  }, []);

  const fetchGradeLevelClasses = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/gradelevelclasses/get-all-gradelevelclasses`);
      setGradeLevelClasses(response.data.classes);
    } catch (error) {
      console.error('Error fetching grade level classes:', error);
      setError('Failed to fetch classes');
    }
  };

  const fetchStudentInfo = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/students/${regNumber}`);
      setStudentInfo(response.data.student);
    } catch (error) {
      console.error('Error fetching student info:', error);
      setError('Failed to fetch student information');
    }
  };

  const downloadPDF = async () => {
    const content = document.getElementById('pdf-content');
    if (!content) return;

    try {
      const scale = 2; // Increase quality of the image
      const canvas = await html2canvas(content, {
        scale: scale,
        useCORS: true,
        logging: false,
        windowWidth: 1024, // Set a fixed width for consistency
        onclone: (clonedDoc) => {
          // Temporarily modify the clone's style for PDF generation
          const clonedContent = clonedDoc.getElementById('pdf-content');
          if (clonedContent) {
            clonedContent.style.width = '1024px';
            clonedContent.style.margin = '0';
            clonedContent.style.padding = '40px';
            clonedContent.style.boxSizing = 'border-box';

            // Force medium screen layout styles
            const commentCells = clonedContent.querySelectorAll('td:nth-child(4), th:nth-child(4)');
            commentCells.forEach(cell => {
              cell.style.whiteSpace = 'normal';
              cell.style.maxWidth = '300px';
              cell.style.wordBreak = 'break-word';
            });

            // Apply flex-row to student info
            const infoItems = clonedContent.querySelectorAll('.flex.flex-col.md\\:flex-row');
            infoItems.forEach(item => {
              item.style.display = 'flex';
              item.style.flexDirection = 'row';
            });
          }
        }
      });

      const imgData = canvas.toDataURL('image/png', 1.0);
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
        compress: true
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate dimensions to fill the page with small margins
      const margin = 10; // 10mm margin
      const usableWidth = pdfWidth - (2 * margin);
      
      // Use the full usable width and maintain aspect ratio
      const imgAspectRatio = canvas.height / canvas.width;
      const imgWidth = usableWidth;
      const imgHeight = usableWidth * imgAspectRatio;

      // Position from top with margin
      const xPos = margin;
      const yPos = margin;

      pdf.addImage(imgData, 'PNG', xPos, yPos, imgWidth, imgHeight, undefined, 'FAST');
      pdf.save(`results_${year}_term${term}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
      setError('Failed to generate PDF');
    }
  };

  const fetchResults = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${BASE_URL}/getsubjectresults`, {
        termID: term,
        year: year,
        classID: classID,
        studentRegNumber: regNumber,
        form: form,
      });
      
      if (response.data.success === false) {
        setError(response.data.message);
        setResults(null);
      } else {
        setResults(response.data.data);
        console.log(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching results:', error);
      setError(error.response?.data?.message || 'Failed to fetch results');
      setResults(null);
    }
    setLoading(false);
  };

  const combinedStudentInfo = studentInfo && results ? {
    ...studentInfo,
    classPosition: results.classPosition,
    subjectResults: results.subjectResults
  } : null;

  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-6 md:px-6">
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h1 className="text-2xl md:text-3xl font-bold text-center text-gray-800 mb-6">Student Results</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Select Term:</label>
            <select
              value={term}
              onChange={(e) => setTerm(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2.5 focus:ring-2 focus:ring-[#11024D]"
            >
              <option value="">Select Term</option>
              <option value="1">Term 1</option>
              <option value="2">Term 2</option>
              <option value="3">Term 3</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Enter Year:</label>
            <input
              type="text"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2.5 focus:ring-2 focus:ring-[#11024D]"
              placeholder="e.g., 2024"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Select Class:</label>
            <select
              value={classID}
              onChange={(e) => setClassID(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2.5 focus:ring-2 focus:ring-[#11024D]"
            >
              <option value="">Select Class</option>
              {gradeLevelClasses.map((classItem) => (
                <option key={classItem.ClassID} value={classItem.ClassID}>
                  {classItem.ClassName}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Enter Form:</label>
            <input
              type="text"
              value={form}
              onChange={(e) => setForm(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2.5 focus:ring-2 focus:ring-[#11024D]"
              placeholder="e.g., 1"
            />
          </div>
        </div>

        <button
          onClick={fetchResults}
          disabled={loading}
          className="w-full mt-6 bg-[#11024D] text-white py-2.5 rounded-md font-medium hover:bg-[#0a0236] transition duration-300 disabled:opacity-50"
        >
          {loading ? 'Loading...' : 'View Results'}
        </button>
      </div>

      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-8">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}

      {results && studentInfo && (
        <div className="bg-white rounded-lg shadow-md">
          <PdfContent student={combinedStudentInfo} year={year} termID={term} />
          
          <div className="p-4 border-t border-gray-200">
            <button
              onClick={downloadPDF}
              className="w-full bg-green-600 text-white py-2.5 rounded-md font-medium hover:bg-green-700 transition duration-300"
            >
              Download Report
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Results;