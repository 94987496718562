import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaHome, FaChartLine, FaClock, FaCog, FaSignOutAlt, FaBell } from 'react-icons/fa';
import AuthContext from '../context/AuthContext';
import logo from '../assets/logo.jpeg'; // Ensure you have a logo.jpeg in your assets folder

const Navbar = () => {
  const { logout } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => setIsOpen(!isOpen);

  return (
    <div className="bg-[#11024D] md:hidden text-white fixed w-full z-50 shadow-md">
      {/* Navbar container */}
      <div className={`flex justify-between items-center p-4 ${isOpen ? 'hidden' : ''}`}>
        {/* Hamburger Icon */}
        <div className="md:hidden">
          <button onClick={toggleNavbar} className="text-white p-2 rounded-lg">
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>

        {/* Logo (hidden when menu is open) */}
        {!isOpen && (
          <div className="ml-auto">
            <img src={logo} alt="Logo" className="w-8" />
          </div>
        )}
      </div>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="bg-[#11024D] text-white w-3/4 h-screen fixed top-0 left-0 p-6 md:hidden">
          <button onClick={toggleNavbar} className="text-white p-2 rounded-lg mb-6">
            <FaTimes size={24} />
          </button>
          <nav>
            <ul className="space-y-6">
              <li className="flex items-center border-b border-gray-600 pb-2">
                <FaHome className="mr-2" />
                <Link to="/profile" onClick={toggleNavbar}>
                  Profile
                </Link>
              </li>
              <li className="flex items-center border-b border-gray-600 pb-2">
                <FaChartLine className="mr-2" />
                <Link to="/results" onClick={toggleNavbar}>
                  Results
                </Link>
              </li>
              <li className="flex items-center border-b border-gray-600 pb-2">
                <FaClock className="mr-2" />
                <Link to="/timetable" onClick={toggleNavbar}>
                  Timetable
                </Link>
              </li>
              <li className="flex items-center border-b border-gray-600 pb-2">
                <FaCog className="mr-2" />
                <Link to="/settings" onClick={toggleNavbar}>
                  Settings
                </Link>
              </li>
              <li className="flex items-center border-b border-gray-600 pb-2">
                <FaHome className="mr-2" />
                <Link to="/financials" onClick={toggleNavbar}>
                  Financials
                </Link>
              </li>
              <li className="flex items-center border-b border-gray-600 pb-2">
                <FaBell className="mr-2" />
                <Link to="/notifications" onClick={toggleNavbar}>
                  Notifications
                </Link>
              </li>
              <li className="flex items-center border-b border-gray-600 pb-2">
                <FaSignOutAlt className="mr-2" />
                <button onClick={logout}>Logout</button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Navbar;
